import React, { useState } from "react";
import { config } from "../../config";
import { Badge } from "react-bootstrap";
import { getLocationsByProprio } from "../../services/locationsService";
import { getVisitesFiltered } from "../../services/visitesService";
import ModalVisites from "../modal-visites";
import ModalBiens from "../modal-biens";
import { stopReloadProteted } from "../../services/utilities";

const ProprietaireItem = ({ data }) => {

    const { gender, firstname, lastname, email, tel, mobile, commune, state, photo } = data;
    const biens = getLocationsByProprio(data.uid);

    let visites = [];
    biens.forEach(bien => {
        const result = getVisitesFiltered({ hlo: bien.loc_hlo });
        if(result && result.length > 0) {
            visites = visites.concat(result);
        }
    });

    const isActif = state === "actif";

    const getProprioIcon = (genre) => {
        switch(genre){
            case 'female':
                return <i className="icon icon-user-femme"></i>;
            case 'male':
                return <i className="icon icon-user-homme"></i>;
            case 'male_and_female':
                return <i className="icon icon-user-couple"></i>;
            case 'male_or_female':
                return <i className="icon icon-user-couple"></i>;
            default:
                return <i className="icon icon-user-homme"></i>;
        }
    }

    const getProprioPhoto = (photo, gender) => {
        // if( photo !== "" && window.navigator.onLine) {
        if(photo) {
            return <img src={config.urlCdnPhotoProps + photo} width="150px" alt="prorpiétaire" />;
        } else {
            return getProprioIcon(gender);
        }
    }

    const [showVisitesModal, setShowVisitesModal] = useState(false);
    const [showBiensModal, setShowBiensModal] = useState(false);

    const onLink = (event) => {
        event.preventDefault();
        stopReloadProteted();
        window.location = event.target.getAttribute('href');
    }

    return (
        <div className="proprio-item">
            <div className="infos">
                <div className="gender">
                    <div className="gender-icon">
                        {getProprioPhoto(photo, gender)}
                    </div>
                    {isActif ? <Badge bg="success" pill><i className="icon icon-check"></i></Badge> : <Badge bg="warning" pill></Badge>}
                </div>
                <div className="nom">{firstname} {lastname}</div>
                <div className="email"><a href={`mailto:${email}`} onClick={onLink}>{email}</a></div>
                <div className="tel">
                    {tel.length > 0 && <a href={`tel:${tel}`} onClick={onLink}>{tel}</a>}
                    {mobile && mobile.length > 0 && <><a href={`tel:${mobile}`} onClick={onLink}>{mobile}</a></>}
                </div>
                <div className="commune"><i className="icon icon-pinpoint"></i> <span className="commune">{commune}</span></div>
            </div>
            <div className="actions">
                {visites.length > 0 && <button type="button" className="btn btn-sm btn-outline-info" onClick={() => setShowVisitesModal(true)}>{visites.length} {visites.length > 1 ? 'visites' : 'visite'}</button>}
                {biens.length > 0 && <button type="button" className="btn btn-sm btn-outline-success" onClick={() => setShowBiensModal(true)}>{biens.length} {biens.length > 1 ? "Biens" : "Bien"}</button>}
            </div>
            {showVisitesModal && <ModalVisites show={showVisitesModal} onHide={() => setShowVisitesModal(false)} visites={visites} />}
            {showBiensModal && <ModalBiens show={showBiensModal} onHide={() => setShowBiensModal(false)} biens={biens} />}
        </div>
    );
}

export default ProprietaireItem;
