import { useEffect, useState } from "react";
import { useHistory } from "react-router";
import { getIsLockable, lockVisite } from "../services/visitesService";
import { subscribeIsOnline } from "../services/isonlineService";
import { Button, OverlayTrigger, Popover, Tooltip } from "react-bootstrap";
import Constantes from "../services/constantes";

const ButtonLockVisite = ({ visite }) => {

    let history = useHistory();

    const [isLockable, setIsLockable] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [lockError, setLockError] = useState(false);
    
    const onLockVisite = async () => {
        setIsLoading(true);
        await lockVisite(visite.uid).then(result => {
            if(result){
                history.push('/visits');
            } else {
                setLockError(true);
                setIsLoading(false);
            }
        });
    }

    useEffect(() => {

        const getLockableStatus = async () => {
            const newData = await getIsLockable(visite);
            if(newData){
                setIsLockable(newData);
            }
        }

        getLockableStatus();

        //ajoute l'event custom 'file_change' créé dans 'file-loader.js'
        //ainsi getLockableStatus est appelé à chaque changement de la liste des fichiers
        window.addEventListener('file_change', getLockableStatus);
        const unsubscribeIsOnline = subscribeIsOnline(getLockableStatus);
        return () => {
            unsubscribeIsOnline(getLockableStatus);
            window.removeEventListener('file_change', getLockableStatus);
        }

    }, [visite]);

    const popover = (
        <Popover id="popover-dupliquer-visite" style={{opacity: isLoading ? '0.5' : '1'}}>
            <Popover.Body>
                <div className="message">
                    <p className="text-center fs-6">Êtes-vous sur de vouloir sceller cette visite ?</p>
                    <ul>
                        <li>Vous pourrez encore la modifier durant 3 mois</li>
                        <li>Tous les thèmes du bien vont être réinitialisés</li>
                    </ul>
                </div>
                {lockError && <div className="alert alert-danger my-3">Une erreur s'est produite lors du scéllage de cette visite !</div>}
            
                <div className="d-flex justify-content-between">
                    <button className="btn btn-outline-secondary" onClick={() => document.body.click()}>Annuler</button>
                    {!lockError &&<button className="btn btn-outline-warning" onClick={onLockVisite}>Sceller</button>}
                </div>
            </Popover.Body>
        </Popover>
    );

    const getTooltip = (props) => {
        return(
            <Tooltip id="tooltip-sceller-visite" {...props}>
                <span>{(isLockable && isLockable.message) ? isLockable.message : Constantes.TEXT_TOOLTIP_SI_NON_SCELLABLE_INCOMPLETE}</span>
            </Tooltip>
        );
    }

    return(
        <OverlayTrigger trigger={["hover", "focus"]} rootClose={true} placement="auto" overlay={getTooltip}>
            <span>
                <OverlayTrigger trigger="click" rootClose={true} placement="bottom" overlay={popover}>
                    <Button className="btn btn-warning text-white btn-lock" disabled={isLockable ? !isLockable.lockable : false} title={Constantes.TEXT_TOOLTIP_SI_SCELLABLE}>
                        <i className="fs-5 icon icon-lock"></i>
                    </Button>
                </OverlayTrigger>
            </span>
        </OverlayTrigger>
    )
};

export default ButtonLockVisite;
