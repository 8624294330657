import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { getVisiteById } from "../services/visitesService";
import { getDateFromTimestamp, getDateLightFromTimestamp } from "../services/utilities";
import { getUserById } from "../services/usersService";
import { getLocationByHLO } from "../services/locationsService";
import { getProprioByUID } from "../services/propriosService";
import { config } from "../config";
import ViewerPDF from "../components/visitePdf/viewer-pdf";

const VisitePdf = ({ visiteUid, isMobile }) => {

    let { id } = useParams();
    if(!id) id = visiteUid;

    const [visite, setVisite] = useState();
    const [infos, setInfos] = useState();

    useEffect(() => {
        if(id){
            getVisiteById(id).then(result => {
                setVisite(result);
            });
        }
    }, [id]);

    useEffect(() => {

        if(!visite) return;

        // Extraction des informations liées à la visite
        const type = config.visits_types.find(t => t.id === visite.type_visite);
        const location = getLocationByHLO(visite.loc_hlo);

        if(!location) return;

        let proprioContact = null;
        const propsUids = location.props.split(',');
        const props = propsUids.map(propUid => getProprioByUID(propUid));

        //le représentant affiché du bien sera le proprio, même s'il n'est pas contact de réservation
        // (si multiple) sera celui de contact (sauf si agence)
        if(props.length > 1)
        {
            let autreType = null;
            props.forEach(prop => {
                console.log(prop.type);
                if(prop.type === "Propriétaire"){
                    proprioContact = prop;
                }else{
                    autreType = prop;
                }
            });
            if(proprioContact === null && autreType !== null){
                proprioContact = autreType;
            }
        }
        else if (props.length === 1){
            proprioContact=props[0];
        }

        var docTitle = "CR_";
        docTitle += (proprioContact && proprioContact.lastname) ? proprioContact.lastname.slice(0, 10) : '';
        docTitle += "_";
        docTitle += (proprioContact && proprioContact.firstname) ? proprioContact.firstname.slice(0, 10) : '';
        docTitle += "_";
        docTitle += getDateLightFromTimestamp(visite.date_update).replace("/", "_").replace("/", "_");
        docTitle += "_AGR.pdf";

        setInfos({
            typeVisite: (type && type.name) || 'Indéfini',
            date: getDateFromTimestamp(visite.date_visite),
            dateCreate: getDateFromTimestamp(visite.date_create),
            dateUpdate: getDateFromTimestamp(visite.date_update),
            userCreate: {"firstname" :visite.user_firstname, "lastname" :visite.user_lastname},
            userUpdate: getUserById(visite.user_update),
            labelModifie: visite.scelle ? "Scellée" : "Modifiée",
            bien: location,
            proprio: proprioContact,
            documentTitle: docTitle
        });

    }, [visite]);

    const getNextLevelInputs = () => {

        const nextLevelInputs = [];

        const findClesFromChildren = (elements, targetValue) => {

            const foundCles = elements.reduce((result, element) => {
                if(element.range && element.range.includes(targetValue) && element.cles >= targetValue){
                    return false;
                }
                if(element.cles && !element.facultatif){
                    const nextCles = Array.isArray(element.cles) ? element.cles : [element.cles];
                    return [...new Set([...result, ...nextCles])];
                } else {
                    if(element.elements){
                        const nextCles = findClesFromChildren(element.elements, targetValue);
                        if( !nextCles || !result ){
                            return false;
                        }else{
                            return [...new Set([...result, ...nextCles])];
                        }
                    } else {
                        return result;
                    }
                }
            }, []);

            return foundCles;
        }

        /**
         * Retourne tous les critères nécessaires pour la clé supérieure du bloc.
         *
         * @param blocks {array} : tableau contenant les elements enfants du bloc
         * @param rootCles {integer} : niveau de clé atteind par le bloc
         * @param nextInputs {array} : tableau cumulant les résultats (les critères)
         * @param cleTarget {integer} : clé à atteindre (au-dessus de la clé estimée ou de la clé calculée)
         */
        const getNextLevelInputsByBlock = (blocks, rootCles, nextInputs, cleTarget) => {

            blocks.forEach(element => {
                /**
                 * seul les "checkbox" ne contiennent pas d'élements enfants
                 */
                switch(element.type)
                {
                    case "checkbox":
                        if(element.cles && !element.valeur && !element.facultatif && element.cles.includes(cleTarget)){
                            nextInputs.push(element);
                        }
                        break;

                    case "radio":
                        if(element.valeur === "oui"){

                            getNextLevelInputsByBlock(element.elements, rootCles, nextInputs, cleTarget);

                        } else {

                            if(element.cles && !Array.isArray(element.cles)){
                                element.cles = [element.cles];
                            }

                            const radioSelected = element.range && element.range.includes(cleTarget);
                            const radioUnselected = element.cles && !element.active && element.cles.includes(cleTarget);

                            if(radioUnselected){
                                nextInputs.push(element);
                            } else if(radioSelected === undefined && radioUnselected === undefined) {
                                if(element.elements && !element.active && !element.facultatif){
                                    // Rechercher les clés parmi les enfants
                                    const nextCles = findClesFromChildren(element.elements, cleTarget);
                                    if(nextCles && nextCles.length && nextCles.includes(cleTarget)){
                                        nextInputs.push(element);
                                    }
                                }
                            }
                        }
                        break;

                    case "radiogroup":
                        if(element.range){
                            if(element.cles < (cleTarget)){
                                getNextLevelInputsByBlock(element.elements, rootCles, nextInputs, cleTarget);
                            }
                        } else if(element.elements) {
                            getNextLevelInputsByBlock(element.elements, rootCles, nextInputs, cleTarget);
                        }
                        break;
                    default:
                        if(element.elements){
                            getNextLevelInputsByBlock(element.elements, rootCles, nextInputs, cleTarget);
                        }
                        break;
                }
            });

            return nextInputs;
        }

        if(visite && visite.contents){

            //la clé à atteindre est celle au-dessus de la clé estimée et à defaut de la clé calculée
            const cleTarget = visite.cles>0?visite.cles+1:visite.cles_calcul+1;

            visite.contents.forEach(block => {

                //écarter les thèmes
                if(!block.group_visite.startsWith('th_')){
                    if(block.content.length){

                        //parcours les blocs principaux (puis récurssif)
                        block.content.forEach(h1 => {
                            const nexts = getNextLevelInputsByBlock(h1.elements, h1.cles, [], cleTarget);
                            if(nexts.length){
                                nextLevelInputs.push({block: h1, inputs: nexts});
                            }
                        });
                    }
                }
            });
        }

        return nextLevelInputs;
    }

    if(!infos) return (<div></div>);

    /**
     * les critères >= à la clé de la visite seront écartés lors de l'affichage :
     * src/components/visitePdf/visite-next-level.js
     */
    return (
        <ViewerPDF visite={visite} infos={infos} isMobile={isMobile} nextLevelInputs={getNextLevelInputs()} />
    );
}


export default VisitePdf;
