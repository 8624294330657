import React, { useState } from "react";
import { Alert, Button } from "react-bootstrap";
import { Redirect } from "react-router";
import { getTempCredentials, loadData } from "../services/loginService";
import logoNomade from "../images/logo-nomade.png";
import useInstallPrompt from "../services/useInstallPrompt";

const PWA = () => {

    const [onNavigator, setOnNavigator] = useState(false);

    const [installPromptEvent, onInstallAccepted] = useInstallPrompt();

    // Détection de la plateforme
    const isMobile = navigator.maxTouchPoints > 1;
    const isIos = (/iPad|iPhone|iPod/.test(navigator.platform) || (navigator.platform === 'MacIntel' && isMobile));
    const isOsx = navigator.platform === 'MacIntel' && navigator.maxTouchPoints === 0;
    const isChrome = navigator.userAgent.toLowerCase().indexOf('chrome') !== -1;

    const renderExplications = () => {

        if(!isIos){
            if(isChrome) {
                if(isMobile){
                    return <p className="text-center">
                        <strong>Pour installer Nomade, actionner le bouton <i className="icon icon-menu-vertical fs-4"></i> de votre navigateur puis choisir « Installer l'application ».</strong>
                    </p>;
                } else {
                    return <p className="text-center">
                        <strong>Pour installer Nomade, actionner le bouton <i className="icon icon-install fs-4"></i> dans la barre de navigation de votre navigateur.</strong>
                    </p>;
                }
            }
        }

        
        if((isOsx && !isChrome) || isIos) {
            return <p className="text-center"><strong>Pour installer Nomade, actionner le bouton <i className="icon icon-share-ios fs-4"></i> puis choisir « Sur l'écran d'accueil ».</strong></p>
        }
    }

    const onContinue = () => {
        /** Nouveau flag usersToLoad à false pour ne plus charger les users du serveur */
        loadData(getTempCredentials(), false).then(result => {
            if(result){
                setOnNavigator(true);
            }
        });
    }

    if(onNavigator){
        return <Redirect to="/" />;
    }

    return (
        <div className="container py-4">
            <div className="w-50 mx-auto py-3">
                <div className="logotype-conteiner mb-3">
                    <img src={logoNomade} alt="Logotype Nomade" className="img-fluid" />
                </div>
                <h1 className="text-center fs-3 mb-3">Installation de NOMADE V2</h1>
                <Alert variant="info">
                    <p>Cette nouvelle version de Nomade bénéficie des dernières technologies internet.
                        Elle peut être installée sur votre terminal comme une application pour pouvoir être utilisée.</p>
                    {installPromptEvent ? <Button onClick={onInstallAccepted}>Installer Nomade…</Button> : renderExplications() }
                </Alert>
                <button className="btn btn-outline-info" onClick={onContinue}>…Ou Continuer dans le navigateur</button>
            </div>
        </div>
    )
};

export default PWA;
