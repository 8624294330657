import { config } from "../config";
import { dbAdd, dbBulkAdd, dbFetchStore, dbGet } from "./indexedDBService";
import { getCurrentUser } from "./usersService";
import { getTimestamp, removeAccents } from "./utilities";

let proprios = [];
const subscribers = [];

const loadProprios = async (credentials) => {

    if(credentials){
        if(window.navigator.onLine){
            await _loadPropriosFromServer(credentials).then(result => {
                proprios = result;
            });
        } else {
            await dbFetchStore('props').then(data => {
                proprios = data;
            });
        }
    } else {
        await dbFetchStore('props').then(data => {
            proprios = data;
        });
    }

    _publish();
    return proprios;

}

const subscribeProprios = (subscriber) => {
    if(subscribers.indexOf(subscriber) === -1){
        subscribers.push(subscriber);
        return _unsubscribe;
    }
}

const getProprios = () => {
    const { email, mdp } = getCurrentUser();
    return _loadPropriosFromServer({login: email, pass: mdp}).then(result => {
        _publish();
        // return proprios;
    });
}

const getLocalProprios = () => {
    return proprios;
}

const getProprioByEmail = (email) => {
    return proprios.find(user => user.email === email);
}

const getProprioByCommune = (commune) => {
    return proprios.find(user => user.commune === commune);
}

const getProprioByFirstname = (firstname) => {
    return proprios.find(user => user.firstname === firstname);
}

const getProprioByLastname = (lastname) => {
    return proprios.find(user => user.lastname === lastname);
}

const getProprioByUID = (uid) => {
    // if(uid.indexOf(',') !== -1){
    //     uid = uid.split(',')[0];
    // }
    return proprios.find(user => user.uid === parseInt(uid));
}

const getPropriosFiletered = (filters) => {

    let result = proprios;

    if(filters.actifs){
        result = result.filter(proprio => proprio.state === filters.actifs);
    }

    if(filters.departement){
        result = result.filter(proprio => proprio.postal_code.startsWith(filters.departement));
    }

    if(filters.texte){
        result = result.filter(proprio => {
            const searchString = removeAccents(`${proprio.firstname} ${proprio.lastname} ${proprio.email} ${proprio.commune}`.toLowerCase());
            const search = removeAccents(filters.texte).toLowerCase();
            return searchString.indexOf(search) !== -1;
        });
    }

    return result;

}

const getGenderName = (genre) => {

    if(genre){

        let gentile = '';

        switch(genre) {
            case 'male_and_female':
                gentile = 'Madame et Monsieur';
                break;
            case 'male_or_female':
                gentile = 'Madame ou Monsieur';
                break;
            case 'male':
                gentile = 'Monsieur';
                break;
            case 'female':
                gentile = 'Madame';
                break;
            default:
                gentile = '';
                break;
        }

        return gentile;

    } else {
        return '';
    }
}

const _loadPropriosFromServer = async (credentials) => {
    try {

        const lastSyncDate = await dbGet('app_params', 'last_sync_proprios').then(result => {
            return result ? result.value : false;
        });

        const lastsyncParam = lastSyncDate ? '&last_sync=' + lastSyncDate : '';

        const host = config.apiData.domain;
        const pass = encodeURIComponent(credentials.pass);
        const fetchUrl = `${host}${config.apiData.entities.props.getList}&login=${credentials.login}&pass=${pass}${lastsyncParam}`;

        const response = await fetch(fetchUrl);
        if(response.ok){
            const data = await response.json();
            return dbBulkAdd('props', data.users).then(async result => {
                proprios = result;
                await dbAdd('app_params', { 'name': 'last_sync_proprios', 'value': getTimestamp() });
                return proprios;
            });
        } else {
            console.warn('MAUVAIS LOGIN');
            return proprios;
        }
    } catch (error) {
        console.warn('ERREUR : ', error);
        return proprios;
    }
}

const _unsubscribe = (subscriber) => {
    const index = subscribers.indexOf(subscriber);
    if(index !== -1){
        subscribers.splice(index, 1);
    }
}

const _publish = () => {
    subscribers.forEach(subscriber => {
        subscriber(proprios);
    });
}

export {
    loadProprios,
    subscribeProprios,
    getProprios,
    getLocalProprios,
    getProprioByEmail,
    getProprioByUID,
    getProprioByLastname,
    getProprioByFirstname,
    getProprioByCommune,
    getPropriosFiletered,
    getGenderName
}
